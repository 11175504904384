<template>
  <div class="content-box">
    <div class="content-header">
      <el-select
        clearable
        v-model="storeFilterType"
        class="input"
        :filterable="false"
        placeholder="门店类型"
      >
        <el-option
          v-for="item in storeTypeList"
          :key="item.val"
          :label="item.name"
          :value="item.val">
        </el-option>
      </el-select>
      <el-select
        clearable
        v-model="status"
        class="input"
        :filterable="false"
        placeholder="请选择门店状态"
      >
        <el-option
          v-for="item in statusOptions"
          :key="item.val"
          :label="item.name"
          :value="item.val">
        </el-option>
      </el-select>
      <el-input
        :value="storeName"
        @input="changeValue($event, 'storeName')"
        maxlength="50"
        class="input"
        clearable
        placeholder="输入门店名称"
      ></el-input>
      <el-input
        :value="ownerName"
        @input="changeValue($event, 'ownerName')"
        maxlength="25"
        class="input"
        clearable
        placeholder="输入门店负责人"
      ></el-input>
      <el-input
        :value="phone"
        @input="changeValue($event, 'phone')"
        class="input"
        maxlength="11"
        clearable
        placeholder="输入门店电话/店长/店员号码"
      ></el-input>
      <el-input
        :value="storeMentorPhone"
        @input="changeValue($event, 'storeMentorPhone')"
        class="input"
        maxlength="11"
        clearable
        placeholder="输入督导/服务跟进号码"
      ></el-input>
      <el-input
        v-model="storeShowName"
        class="input"
        clearable
        placeholder="输入门店外显名称"
      ></el-input>
      <el-input
        v-model="mid"
        class="input"
        clearable
        placeholder="输入银联商户号"
      ></el-input>
      <el-select
        clearable
        v-model="mallEnable"
        class="input"
        :filterable="false"
        placeholder="开启商城接单"
      >
        <el-option
          v-for="item in mallEnableOptions"
          :key="item.value"
          :label="item.label"
          :value="item.value">
        </el-option>
      </el-select>
      <el-select
        clearable
        v-model="merchantId"
        class="input"
        :filterable="false"
        placeholder="品牌商名称"
      >
        <el-option
          v-for="item in merchantOptions"
          :key="item.id"
          :label="item.name"
          :value="item.id">
        </el-option>
      </el-select>
      <el-button type="plain" class="cotent-btn" @click="handleSearch"
        >搜索</el-button
      >
      <el-button
        type="plain"
        class="cotent-btn-big"
        icon="el-icon-plus"
        @click="handleEdit"
        >新增门店</el-button
      >
    </div>
    <div class="table-list">
      <el-table
        :data="tableData"
        style="width: 100%"
        :max-height="tableMaxheight"
        :header-cell-style="headerCellStyle"
        border
        v-loading="loading"
        :cell-style="cellStyle"
      >
        <el-table-column prop="id" label="序号" width="80"> </el-table-column>
        <el-table-column prop="storeType" label="门店类型" width="180">
          <template slot-scope="scope">
            {{ handleColumnValue('storeType', scope.row.storeType) }}
            <div>
              {{scope.row.merchantName}}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="statusDesc" label="门店状态" width="80">
        </el-table-column>
        <el-table-column prop="storeName" label="门店名称" width="180">
          <template #default="{row}">
            <span @click="viewStoreDetail(row)" class="a-span">{{row.storeName}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="storeShowName" label="门店外显名称" width="180">
        </el-table-column>
        <el-table-column prop="storePhone" label="门店电话" width="110">
          <template slot-scope="scope">
            {{ handleColumnValue('default', scope.row.storePhone) }}
          </template>
        </el-table-column>
        <el-table-column prop="addressDesc" label="门店城市" width="150">
        </el-table-column>
        <el-table-column prop="ownerName" label="门店负责人" width="120"></el-table-column>
        <el-table-column prop="phone" label="联系方式" width="120"> </el-table-column>
        <el-table-column prop="restDateList" label="休息日期" width="150">
          <template #default="{row}">
            <el-popover
              placement="top-start"
              width="260"
              trigger="hover"
              :content="getRestDateDesc(row.restDateList)">
              <div slot="reference" class="ellipsis-text-2">{{getRestDateDesc(row.restDateList)}}</div>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column prop="storeUserOwners" label="门店管理员"  width="100">
          <template #default="{ row }">
            <el-popover
              popper-class="store-owner-popup"
              placement="top"
              width="100px"
              title="门店管理员"
              trigger="click"
              >
              <template #default>
                <div>
                  <div class="store-owner-popup__content">
                    <p v-for="item, index in row.storeUserOwners" :key="index">
                      {{item.phone}}
                    </p>
                  </div>
                </div>
              </template>
              <template #reference>
                <el-button slot="reference">{{row.storeUserOwners?.length || ""}}</el-button>
              </template>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column prop="storeDevice" label="关联设备" width="100">
          <template #default="{row}">
            <el-button @click="viewAssDevice(row)" type="text">{{row.storeDevice?.length}}</el-button>
          </template>
        </el-table-column>
        <el-table-column prop="saasStoreName" label="saas系统绑定" width="120">
        </el-table-column>
        <el-table-column prop="mid" label="银联商户号" width="150">
        </el-table-column>
        <el-table-column prop="mallEnable" label="开启商城接单" width="120">
          <template #default="{row}">
            {{row.mallEnable ? "启用" : "关闭"}}
          </template>
        </el-table-column>
        <el-table-column prop="storeInfo" label="备注" min-width="100">
          <template #default="{row}">
          <el-popover
              placement="top-end"
              width="300"
              trigger="hover">
              <p>{{ row.storeInfo }}</p>
              <div class="label-wrap c-mouse" slot="reference">{{ row.storeInfo }}</div>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column prop="updateBy" label="操作人" min-width="100">
        </el-table-column>
        <el-table-column prop="updateTime" label="最后操作时间" min-width="160">
        </el-table-column>
        <el-table-column prop="createTime" label="创建时间" min-width="160">
        </el-table-column>
        <el-table-column label="操作" width="120" fixed="right">
          <template slot-scope="scope">
            <el-button type="text" @click="handleEdit(scope.row)"
              >编辑</el-button
            >
            <el-button type="text" @click="handleShow(scope.row)"
              >门店数据</el-button
            >
            <el-button type="text" @click="handleRefreshCode(scope.row)"
              >设备刷码</el-button
            >
            <el-popover
              placement="top"
              width="260"
              trigger="click"
              @show="handleViewRemainTimes(scope.row)"
              @hide="handleHideRemainTimes">
              <div class="remain-times-tip">
                <transition name="fade">
                  <div v-if="remainTimesLoaded">
                    <label>诊断剩余次数</label>
                    <div class="remain-times-split"></div>
                    <div class="remain-times-box">
                      <div>舌诊 {{ tongueTimes }}</div>
                      <div>体质辨识 {{ physiqueTimes }}</div>
                      <div>脉诊 {{ pulseTimes }}</div>
                      <div>肺功能 {{ lungTimes }}</div>
                    </div>
                  </div>
                </transition>
              </div>
              <el-button slot="reference">诊断剩余</el-button>
            </el-popover>
          </template>
        </el-table-column>
      </el-table>

      <!-- 分页 -->
      <el-pagination
        style="margin-top: 20px"
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="pageSizes"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="itemTotal"
      ></el-pagination>

      <el-dialog
        title="新增/编辑门店"
        :visible.sync="dialogVisible"
        width="900px"
        :close-on-click-modal="false"
        :before-close="dialogClose"
      >
        <div class="edit-box">
          <el-form
            label-position="left"
            label-width="120px"
            :model="formData"
            ref="validateForm"
            :validate-on-rule-change="false"
            :rules="rules"
          >
            <el-form-item label="门店类型:" prop="storeType">
              <el-select
                v-model="formData.storeType"
                class="dialogInput"
                placeholder="选择门店类型">
                <el-option
                  v-for="item in storeTypeList"
                  :key="item.val"
                  :label="item.name"
                  :value="item.val">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="门店状态:" prop="status">
              <el-select
                v-model="formData.status"
                class="dialogInput"
                :filterable="false"
                placeholder="请选择门店状态"
              >
                <el-option
                  v-for="item in statusOptions"
                  :key="item.val"
                  :label="item.name"
                  :value="item.val">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item class="el-form-item__row" label="休息日期:">
              <el-date-picker
                class="dialogInput"
                type="dates"
                :picker-options="restDateListPickerOptions"
                @change="restDateListListChange"
                v-model="formData.restDateList"
                placeholder="选择一个或多个日期">
              </el-date-picker>
              <!-- README:这个picker 留着，后续做假的选择框可以用到 -->
              <!-- style="position: absolute;z-index: 2;opacity: 0;" -->
              <!-- <el-date-picker
                style="position: absolute;z-index: 1;"
                class="dialogInput"
                type="dates"
                placeholder="选择一个或多个日期22">
              </el-date-picker> -->
              <div>
                <el-tag
                :key="tag.id"
                v-for="tag,index in formData.restDateList"
                closable
                :disable-transitions="false"
                @close="handlerestDateListClose(tag, index)"
              >
                {{getTimeString(tag)}}
              </el-tag>
              </div>
            </el-form-item>

            <el-form-item label="门店名称:" prop="storeName">
              <el-input
                v-model="formData.storeName"
                class="dialogInput"
                autocomplete='new-password'
                maxlength="50"
                placeholder="请输入门店名称"
              >
              </el-input>
            </el-form-item>
            <el-form-item label="门店电话:" prop="storePhone">
              <el-input
                v-model="formData.storePhone"
                class="dialogInput"
                autocomplete='new-password'
                @input="inputStorePhone"
                maxlength="11"
                placeholder="请输入门店联系电话"
              ></el-input>
            </el-form-item>
            <el-form-item label="门店负责人:" prop="ownerName">
              <el-input
                v-model="formData.ownerName"
                class="dialogInput"
                autocomplete='new-password'
                maxlength="25"
                placeholder="请输入门店老板姓名"
              >
              </el-input>
            </el-form-item>
            <el-form-item label="联系方式:" prop="phone">
              <el-input
                v-model="formData.phone"
                class="dialogInput"
                autocomplete='new-password'
                @input="inputValue"
                maxlength="11"
                placeholder="请输入门店老板电话"
              ></el-input>
            </el-form-item>
            <el-form-item class="el-form-item__row" label="关联管理员:">
              <el-select
                clearable
                :value="undefined"
                @change="setCurUser"
                class="dialogInput"
                autocomplete='new-password'
                remote
                :remote-method="getUserList"
                @focus="getUserList"
                filterable
                :loading="userLoading"
                placeholder="请输入门店店长手机号（开启门店看板）"
              >
                <el-option
                  v-for="item in allUserList"
                  :key="item.id"
                  :label="item.nickname + ': ' + item.phone"
                  :value="item"
                >
                </el-option>
              </el-select>
              <div>
                <el-tag
                :key="tag.id"
                v-for="tag in dynamicTags"
                closable
                :disable-transitions="false"
                @close="handleClose(tag)"
              >
                {{ tag.phone }}
              </el-tag>
              </div>
            </el-form-item>
            <el-form-item class="el-form-item__row" label="门店店员:">
              <el-select
                clearable
                :value="undefined"
                @change="setCurClerk"
                class="dialogInput"
                autocomplete='new-password'
                remote
                :remote-method="getUserList"
                @focus="getUserList"
                filterable
                :loading="userLoading"
                placeholder="请输入店员手机号（开启门店看板）"
              >
                <el-option
                  v-for="item in allUserList"
                  :key="item.id"
                  :label="item.nickname + ': ' + item.phone"
                  :value="item"
                >
                </el-option>
              </el-select>
              <div>
                <el-tag
                :key="tag.id"
                v-for="tag in storeMemberList"
                closable
                :disable-transitions="false"
                @close="handleClerkClose(tag)"
              >
                {{ tag.phone }}
              </el-tag>
              </div>

            </el-form-item>
            <el-form-item label="门店城市:" prop="address">
              <el-cascader
                style="width: 300px"
                size="large"
                :options="regionData"
                v-model="formData.address"
              >
              </el-cascader>
            </el-form-item>
            <el-form-item label="门店具体地址:" prop="shipAddress">
              <el-input
                v-model="formData.shipAddress"
                class="dialogInput"
                autocomplete='new-password'
                maxlength="100"
                placeholder="请填写门店详细地址"
              ></el-input>
            </el-form-item>
            <el-form-item label="门店经纬度:" prop="longAndLat">
              <el-input
                v-model="formData.longAndLat"
                class="dialogInputSmall"
                autocomplete='new-password'
                placeholder="点击右侧图标从腾讯地图复制经纬度"
              ></el-input>
              <i class="el-icon-map-location iconPosition"
                @click="openTencentLBS"></i>
            </el-form-item>
            <el-form-item label="门店督导:" prop="storeMentor">
              <el-select
                clearable
                :value="undefined"
                @change="setStoreMentor"
                class="dialogInput"
                autocomplete='new-password'
                remote
                :remote-method="getUserList"
                @focus="getUserList"
                filterable
                :loading="userLoading"
                placeholder="请输入门店督导电话"
              >
                <el-option
                  v-for="item in allUserList"
                  :key="item.id"
                  :label="item.nickname + ': ' + item.phone"
                  :value="item"
                >
                </el-option>
              </el-select>
              <el-tag v-if="mentorTag" closable :disable-transitions="false" @close="setStoreMentor(null)" >
                {{ mentorTag.nickname + ": " +  mentorTag.phone }}
              </el-tag>
            </el-form-item>
            <el-form-item label="服务跟进:" prop="storeSupport">
              <el-select
                clearable
                :value="undefined"
                @change="setStoreSupport"
                class="dialogInput"
                autocomplete='new-password'
                remote
                :remote-method="getUserList"
                @focus="getUserList"
                filterable
                :loading="userLoading"
                placeholder="请输入服务跟进人电话"
              >
                <el-option
                  v-for="item in allUserList"
                  :key="item.id"
                  :label="item.nickname + ': ' + item.phone"
                  :value="item"
                >
                </el-option>
              </el-select>
              <el-tag v-if="supportTag" closable :disable-transitions="false" @close="setStoreSupport(null)" >
                {{ supportTag.nickname + ": " + supportTag.phone }}
              </el-tag>
            </el-form-item>
            <el-form-item label="SaaS系统绑定:" prop="saasStore">
              <el-select
                clearable
                v-model="formData.saasStore"
                class="dialogInput"
                autocomplete='new-password'
                remote
                :remote-method="getSaasList"
                @change="saasChange"
                @focus="getSaasList"
                filterable
                placeholder="请输入伊智系统门店名称"
                value-key="saaSStoreId"
                :loading="saasStoreLoading"
              >
                <el-option
                  v-for="(item) in allSaasStoreList"
                  :key="item.saaSStoreId"
                  :label="item.saaSStoreName"
                  :value="item"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="备注:">
              <el-input
                type="textarea"
                autocomplete='new-password'
                v-model="formData.storeInfo"
                class="dialogInput"
                maxlength="100"
                placeholder="请输入备注"
              ></el-input>
            </el-form-item>
          </el-form>
          <div slot="footer" class="dialog-footer">
            <el-button @click="dialogClose">取消</el-button>
            <el-button type="primary" @click="dialogConfirm">提交</el-button>
          </div>
        </div>
      </el-dialog>
    </div>
    <el-drawer
      :title="dateShowFrom.storeName"
      :visible.sync="dataDialogVisible"
      direction="rtl"
      :before-close="dataHandleClose"
    >
      <div class="data-box">
        <div class="data-select">
          <el-radio-group v-model="type" @input="changeDate">
            <el-radio-button label="day">今日</el-radio-button>
            <el-radio-button label="yesterday">昨日</el-radio-button>
            <el-radio-button label="month">本月</el-radio-button>
            <el-radio-button label="preMonth">上月</el-radio-button>
            <el-radio-button label="year">全年</el-radio-button>
          </el-radio-group>
        </div>
        <el-descriptions
          :colon="false"
          class="margin-top"
          :column="3"
          direction="vertical"
        >
          <template slot="title">
            <div class="data-title">营收情况</div>
          </template>
          <el-descriptions-item>
            <template slot="label">
              总营业额(元)
              <el-popover
                placement="bottom-end"
                width="280"
                trigger="click"
                content="总营业额=服务业绩+商品业绩(服务业绩: 服务项目收到的现金+卡耗总额；商品业绩：售出商品产生的现金+卡耗总额)">
                <img class="issue-icon" slot="reference" src="https://yuyi-1301954635.cos.ap-guangzhou.myqcloud.com/icon/ic_demand_issue.png" />
              </el-popover>
            </template>{{
            totalMoney
          }}</el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              总现金(元)
              <el-popover
                placement="bottom-end"
                width="280"
                trigger="click"
                content="总现金=门店总现金类收款（门店所有现金类支付方式的收款总额，未减去退卡、退套餐支付出去的现金金额)">
                <img class="issue-icon" slot="reference" src="https://yuyi-1301954635.cos.ap-guangzhou.myqcloud.com/icon/ic_demand_issue.png" />
              </el-popover>
            </template>{{
            totalCash
          }}</el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              服务业绩(元)
              <el-popover
                placement="bottom-end"
                width="280"
                trigger="click"
                content="服务业绩=服务实际入账，这里均不含会员卡充值和套餐销售的收入(如存在商品和服务混合订单，则按售价比例进行拆分实际入账金额)">
                <img class="issue-icon" slot="reference" src="https://yuyi-1301954635.cos.ap-guangzhou.myqcloud.com/icon/ic_demand_issue.png" />
              </el-popover>
            </template>{{
            serviceActualIncome
          }}</el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              商品业绩(元)
              <el-popover
                placement="bottom-end"
                width="280"
                trigger="click"
                content="商品业绩=商品实际入账，这里均不含会员卡充值和套餐销售的收入(如存在商品和服务混合订单，则按售价比例进行拆分实际入账金额)">
                <img class="issue-icon" slot="reference" src="https://yuyi-1301954635.cos.ap-guangzhou.myqcloud.com/icon/ic_demand_issue.png" />
              </el-popover>
            </template>{{
            goodsActualIncome
          }}</el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              开卡总额(元)
              <el-popover
                placement="bottom-end"
                width="220"
                trigger="click"
                content="开卡总额=充值总额+开卡工本费">
                <img class="issue-icon" slot="reference" src="https://yuyi-1301954635.cos.ap-guangzhou.myqcloud.com/icon/ic_demand_issue.png" />
              </el-popover>
            </template>{{
            totalCard
          }}</el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              套餐业绩(元)
              <el-popover
                placement="bottom-end"
                width="220"
                trigger="click"
                content="指在疗程/套餐和自定义套餐内全部支付方式购买的服务和商品的实际收入总和。">
                <img class="issue-icon" slot="reference" src="https://yuyi-1301954635.cos.ap-guangzhou.myqcloud.com/icon/ic_demand_issue.png" />
              </el-popover>
            </template>{{
              course
          }}</el-descriptions-item>
        </el-descriptions>
        <el-descriptions
          :colon="false"
          class="margin-top"
          :extra="'用户数量（历年总数）：' + (dateShowFrom.userData.totalUserNumber || 0)"
          :column="3"
          direction="vertical"
        >
          <template slot="title">
            <div class="data-title">数据概括</div>
          </template>
          <el-descriptions-item label="用户进店数">{{
            dateShowFrom.userData.storeUserNumber
          }}</el-descriptions-item>
          <el-descriptions-item label="检测人数">{{
            dateShowFrom.userData.detectionUserNumber
          }}</el-descriptions-item>
          <el-descriptions-item label="调理人数">{{
            dateShowFrom.userData.heathUserNumber
          }}</el-descriptions-item>
        </el-descriptions>
        <el-descriptions
          :colon="false"
          class="margin-top"
          title="检测概括"
          :extra="
            '使用次数（历年总数）：' + (dateShowFrom.userDetection.totalDetectionCount || 0)
          "
          :column="3"
          direction="vertical"
        >
          <template slot="title">
            <div class="data-title">检测概括</div>
          </template>
          <el-descriptions-item label="智能脉诊">{{
            dateShowFrom.userDetection.pulseTakingUserCount
          }}</el-descriptions-item>
          <el-descriptions-item label="智能舌诊">{{
            dateShowFrom.userDetection.tongueUserNumber
          }}</el-descriptions-item>
          <el-descriptions-item label="体质辨证">{{
            dateShowFrom.userDetection.physiqueTestNumber
          }}</el-descriptions-item>
          <el-descriptions-item label="肺功能检测">{{
            dateShowFrom.userDetection.pefNumber
          }}</el-descriptions-item>
        </el-descriptions>
        <el-descriptions
          :colon="false"
          class="margin-top"
          title="调理概括"
          :extra="
            '使用次数（历年总数）：' +
            (healthTotal || 0)
          "
          :column="3"
          direction="vertical"
        >
          <template slot="title">
            <div class="data-title">调理概括</div>
          </template>
          <template v-for="(health, index) in dateShowFrom.userHealth">
            <el-descriptions-item :label="health.deviceTypeName" :key="index">{{
              health.healthUserCount
            }}</el-descriptions-item>
          </template>
        </el-descriptions>
        <el-descriptions
          class="margin-top-black"
          title="检测设备期限"
          :column="2"
        >
          <template slot="title">
            <div class="data-title">检测设备期限</div>
          </template>
          <template v-for="detection in dateShowFrom.detectionDevice">
            <el-descriptions-item
              :key="detection.id"
              :label="detection.deviceName"
            >{{mixFrequency(detection)}}</el-descriptions-item>
            <el-descriptions-item label="到期时间" :key="detection.id">{{
              mixTime(detection, "detection")
            }}</el-descriptions-item>
          </template>
        </el-descriptions>
        <el-descriptions
          class="margin-top-black"
          title="调理设备期限"
          :column="2"
          :colon="false"
        >
          <template slot="title">
            <div class="data-title">调理设备期限</div>
          </template>
          <template v-for="(health, index) in dateShowFrom.healthDevice">
            <el-descriptions-item :label="health.deviceName" :key="index"
              ></el-descriptions-item
            >
            <el-descriptions-item label="到期时间:" :key="index">{{
              mixTime(health)
            }}</el-descriptions-item>
          </template>
        </el-descriptions>
      </div>
    </el-drawer>
    <AssociatedDevice :dialogVisible.sync="associatedDeviceVisible" :storeId="viewStoreInfo.id" :storeName="viewStoreInfo.storeName" />
    <StoreDetail :storeTypeList="storeTypeList" :storeDetailVisible.sync="storeDetailVisible" :storeDetail="storeDetailInfo"></StoreDetail>
  </div>
</template>

<script>
import { Watch, Mixins, Component } from "vue-property-decorator";
import tablePagination from "@/mixins/tablePagination";
import loading from "@/mixins/loading";
import Resize from "@/mixins/resize";
import RegionData from "@/mixins/regionData";

import {
  storeList,
  createStore,
  editStore,
  detectionDeviceDeadline,
  healthDeviceDeadline,
  userDataCount,
  userDetectionCount,
  userHealthCount,
  healthTotalCount,
  getSaasStoreList,
  getUserBusinessCount,
  updateWxMiniUrl
} from "@/api/store";
import { storeDiagnoses } from "@/api/tongue";
import { getUserList } from "@/api/userMember";
import { getMerchantList } from "@/api/prescriptionDb";

// 工具
import deepCopy from "@/utils/deepCopy";
import inputPrice from "@/utils/inputPrice";
import { debounce, cloneDeep } from "lodash";
import { formatMoney } from "@/utils/common";
import { getNavList as getNavListApi } from "@/api/mini";

import AssociatedDevice from './components/associatedDevice.vue';
import StoreDetail from './components/storeDetail.vue';

import dayjs from "dayjs";

const initFormData = {
  storeName: "",
  status: "",
  restDateList: [],
  ownerName: "",
  phone: "",
  storeInfo: "",
  saasStore: {},
  storeType: null,
  address: [],
  shipAddress: '',
  longAndLat: '',
  storeLong: 0,
  storeLat: 0,
  storePhone: '',
  storeMentor: 0,
  storeSupport: 0,
  merchantId: "",
};

@Component({
  components: {AssociatedDevice,StoreDetail}
})
export default class StoreList extends Mixins(loading, tablePagination, Resize, RegionData) {
  tongueTimes = 0; // 舌诊剩余次数
  physiqueTimes = 0; // 体质辨识剩余次数
  pulseTimes = 0; // 脉诊剩余次数
  lungTimes = 0; // 肺功能检测剩余次数
  remainTimesLoaded = false;
  storeFilterType = null; // 门店类型的搜索条件
  storeTypeList = []; // 门店类型枚举列表
  userLoading = false;
  healthTotal = 0;
  type = "day";
  curStoreId = "";
  allUserList = [];
  dynamicTags = [];
  supportTag = null;
  mentorTag = null;
  allSaasStoreList = [];
  storeName = "";
  ownerName = "";
  phone = "";
  storeMentorPhone = "";
  mallEnable = null;
  merchantId = "";
  mid = "";
  storeShowName = "";
  tableData = [];
  itemTotal = 0;
  dialogVisible = false;
  dataDialogVisible = false;
  formData = {
    storeName: "",
    ownerName: "",
    phone: "",
    storeInfo: "",
    saasStore: {},
    storeType: null,
    status: "",
    restDateList: [],
    address: [],
    shipAddress: '',
    longAndLat: '',
    storeLong: 0,
    storeLat: 0,
    storePhone: '',
    storeMentor: 0,
    storeSupport: 0,
  };
  dateShowFrom = {
    detectionDevice: [],
    healthDevice: [],
    userData: {},
    userDetection: {},
    userHealth: [],
  };
  totalCard = 0; // 开卡总额
  totalCash = 0; // 总现金
  totalMoney = 0; // 总营业额
  goodsActualIncome = 0; // 商品业绩
  serviceActualIncome = 0; // 服务业绩
  course = 0;
  saasStoreLoading = false;

  // 1接单，0不接单
  mallEnableOptions = [
    {label: "启用", value: 1}, { label: "关闭", value: 0 }
  ]
  
  rules = {};
  rulesForCreate = {
    storeType: [{ required: true, message: "请选择门店类型", trigger: "change" }],
    status: [{ required: true, message: "请选择门店状态", trigger: "change" }],
    storeName: [{ required: true, message: "请输入门店名称", trigger: "blur" }],
    ownerName: [{ required: true, message: "请输入门店负责人", trigger: "blur" }],
    address: [{ required: true, message: "请选择门店所在城市", trigger: "change" }],
    shipAddress: [{ required: true, message: "请输入门店具体地址", trigger: "blur" }],
    phone: [
      { required: true, message: "请输入11位数电话号码", trigger: "blur" },
    ],
    storePhone: [
      { required: true, message: "请输入门店电话", trigger: "blur" },
    ],
    longAndLat: [
      { required: true, message: "请填写门店经纬度", trigger: "blur" },
      { validator: this.validateLatAndLng, trigger: 'blur' }
    ]
  };
  rulesForEdit = {
    status: [{ required: true, message: "请选择门店状态", trigger: "change" }],
    storeName: [{ required: true, message: "请输入门店名称", trigger: "blur" }],
    ownerName: [{ required: true, message: "请输入门店负责人", trigger: "blur" }],
    phone: [
      { required: true, message: "请输入11位数电话号码", trigger: "blur" },
    ],
    longAndLat: [
      { validator: this.validateLatAndLng, trigger: 'blur' }
    ],
  };

  restDateListPickerOptions = {
    disabledDate(v) {
      return v.getTime() < new Date().getTime() - 86400000;
    }
  }

  associatedDeviceVisible = false;
  viewStoreInfo = {};

  storeDetailVisible = false;
  storeDetailInfo = {};

  status = "";
  
  statusOptions = [
    { name: "营业中", val: 0 },
    { name: "歇业", val: 2 },
    { name: "选址中", val: 3 },
    { name: "建店中", val: 4 }
  ]

  storeMemberList = [];

  merchantOptions = [];

  getMerchantList() {
    getMerchantList({ pageNum: 1, pageSize: 100 }).then((res) => {
      console.log("res", res)
      this.merchantOptions = res.data.list;
    })
  }

  getStoreStatusDesc(status) {
    return this.statusOptions?.find(el => el.val == status)?.name || "";
  }

  getRestDateDesc(restDateList) {
    if (restDateList && !restDateList.length) return ""
    return (restDateList || [])?.map((el) => dayjs(el).format("MM-DD"))?.join("、");
  }

  viewAssDevice(row) {
    this.viewStoreInfo = row;
    this.associatedDeviceVisible = true;
  }

  validateLatAndLng(rule, value, callback) {
    let isValid = false;
    if (value && value.indexOf(',') > 0) {
      let lngAndLat = value.split(',');
      if (lngAndLat.length == 2) {
        let lat = parseFloat(lngAndLat[0]);
        let lng = parseFloat(lngAndLat[1]);
        if (lat > 0 && lng > 0) {
          isValid = true;
        }
      }
    }

    if (!isValid && value) {
      callback(new Error('请复制正确的经纬度'));
    } else {
      callback();
    }
  }

  mixTime(detection, type) {
    detection.limitStatus = detection.limitStatus ?? 0;
    // limitStatus 为1 就是无限期
    if (detection.limitStatus == 1) {
      return "无限期";
    } else {
      const expireTime = detection.expireTime;
      // limitStatus 为0 要兼容除舌诊外的设备，还需要判断9999
      if (!expireTime) {
        if (type == "detection") return "已过期";
        return "无限期";
      }
      if (new Date(expireTime).getFullYear() > 8888 || !expireTime) {
        return "无限期";
      }
      return expireTime;
    }
  }
  mixFrequency(detection) {
    if(detection.remainNumber <= -1){
      return '无限次';
    }
    return `余${detection.remainNumber ?? 0}次`
  }

  mounted() {
    const { storeName, merchantId } = this.$route.query;
    this.storeName = storeName ?? "";
    this.merchantId = merchantId ? parseInt(merchantId) : "";
    this.getStoreTypeList();
    this.windowResize(360)
    this.getMerchantList();
  }

  viewStoreDetail(row) {
    this.storeDetailVisible = true;
    this.storeDetailInfo = row;
  }

  // 查询门店类型字典
  getStoreTypeList() {
    getNavListApi('store_type').then((res) => {
      let dists = res.data || [];
      let types = [];
      dists.forEach(item => {
        types.push({name: item.dictValue, val: item.id});
      });
      this.storeTypeList = types;
      this.getData();
    });
  }

  handleColumnValue(type, val) {
    if (type == 'default') {
      return val ? val : '-';
    } else if (type == 'storeType') {
      let typeName = '-';
      for (let item of this.storeTypeList) {
        if (item.val == val) {
          typeName = item.name;
          break;
        }
      }
      return typeName;
    }
    return val;
  }

  convertAddressLabel(val) {
    let addr = [];
    let addrComponents = val && typeof val === 'string' ? val.split(',') : [];
    if (addrComponents.length == 3) {
        addr = this.getItemFromRegions(0, addrComponents, [], this.regionData);
    }
    return addr.join('/');
  }

  // 通过省市区字典反查出地理名称
  getItemFromRegions(index, vals, addresses, regions) {
    let addr = addresses ? addresses : [];
    let children = [];
    for (let item of regions) {
      if (item.value == vals[index]) {
        addr.push(item.label);
        children = item.children;
        break;
      }
    }
    if (children && children.length > 0 && vals.length > index + 1) {
      addr = this.getItemFromRegions(index + 1, vals, addr, children);
    }
    return addr;
  }

  getData() {
    this.showLoading();
    storeList({
      storeType: this.storeFilterType,
      storeName: this.storeName,
      ownerName: this.ownerName,
      phone: this.phone,
      storeMentorPhone: this.storeMentorPhone,
      pageNum: this.currentPage,
      pageSize: this.pageSize,
      status: this.status,
      mid: this.mid,
      mallEnable: this.mallEnable,
      storeShowName: this.storeShowName,
      merchantId: this.merchantId,
    })
      .then((res) => {
        this.hideLoading();
        this.setRegionData().finally(() => {
          this.tableData = res.data.list;
          this.itemTotal = res.data.total;
          this.tableData.forEach((el) => {
            el.addressDesc = this.convertAddressLabel(el.address);
            el.statusDesc = this.statusOptions?.find(s => s.val == el.status)?.name || "";
          })
        })
      })
      .catch((err) => {
        this.hideLoading();
      });
  }

  //重置分页参数
  resetPages() {
    this.currentPage = 1;
  }

  // 头部输入
  changeValue(val, type) {
    if (type === "phone") {
      let phone = inputPrice(val, "number");
      this[type] = phone || "";
    } else if(type === "storeMentorPhone") {
      let storeMentorPhone = inputPrice(val, "number");
      this[type] = storeMentorPhone || "";
    } else {
      this[type] = val;
    }
  }
  inputValue(val) {
    let phone = inputPrice(val, "number");
    this.formData.phone = phone || "";
  }

  inputStorePhone(val) {
    let phone = inputPrice(val, "number");
    this.formData.storePhone = phone || "";
  }

  setStoreMentor(cur) {
    if (cur == null) {
      this.mentorTag = null;
      return;
    }
  // 手机号为空或者重复添加的数据将自动跳过
    if (!cur.phone || cur.phone.length == 0 || !cur.id) {
        return;
      }
    this.mentorTag = cur;
  }

  setStoreSupport(cur) {
    if (cur == null) {
      this.supportTag = null;
      return;
    }
    // 手机号为空或者重复添加的数据将自动跳过
    if (!cur.phone || cur.phone.length == 0 || !cur.id) {
      return;
    }
    this.supportTag = cur;
  }

  openTencentLBS() {
    let href = 'https://lbs.qq.com/getPoint/';
    window.open(href, "_blank");
    return true;
  }

  // 搜索
  handleSearch() {
    this.resetPages();
    this.getData();
  }

  handleEdit(row) {
    const storeInfo = {};
    if (row.id) {
      Object.assign(storeInfo, row)
    }
    this.$router.push({
      path: "/storeFill",
      query: { storeInfo: JSON.stringify(storeInfo) },
    })
  }

  // 编辑的时候，要给下拉框列表赋值，不然就是空白的
  echoSaasStoreList(row) {
    this.allSaasStoreList = [{ saaSStoreId: row.saasStoreId, saaSStoreName: row.saasStoreName }];
    this.formData.saasStore = this.allSaasStoreList[0];
  }

  handleShow(row) {
    this.dataDialogVisible = true;
    this.dateShowFrom.storeName = row.storeName;
    this.curStoreId = row.id;
    this.dataHandleInit();
  }

  handleRefreshCode(row) {
    updateWxMiniUrl(row.id).then(() => {
      this.$message.success("操作成功");
    })
  }

  handleViewRemainTimes(row) {
    storeDiagnoses({
      storeId: row.id,
      pageNum: 1,
      pageSize: 100
    }).then((res) => {
      let remainTimes = res.data && res.data.list ? res.data.list : [];
      for (let item of remainTimes) {
        if (item.diagnosesTypeId == 10001) {
          this.tongueTimes = item.tongueRemainingNumber < 0 ? '无限次' : item.tongueRemainingNumber;
        } else if (item.diagnosesTypeId == 10003) {
          this.pulseTimes = item.tongueRemainingNumber < 0 ? '无限次' : item.tongueRemainingNumber;
        } else if (item.diagnosesTypeId == 10004) {
          this.physiqueTimes = item.tongueRemainingNumber < 0 ? '无限次' : item.tongueRemainingNumber;
        } else if (item.diagnosesTypeId == 10005) {
          this.lungTimes = item.tongueRemainingNumber < 0 ? '无限次' : item.tongueRemainingNumber;
        }
      }
      this.remainTimesLoaded = true;
    });
  }

  handleHideRemainTimes() {
    // 重置数据
    this.remainTimesLoaded = false;
    this.tongueTimes = 0;
    this.physiqueTimes = 0;
    this.pulseTimes = 0;
    this.lungTimes = 0;
  }

  dataHandleClose() {
    this.dataDialogVisible = false;
    this.totalMoney = 0;
    this.totalCash = 0;
    this.goodsActualIncome = 0;
    this.goodsActualIncome = 0;
    this.serviceActualIncome = 0;
    this.dateShowFrom.detectionDevice = [];
    this.dateShowFrom.healthDevice = [];
    this.dateShowFrom.userData = [];
    this.dateShowFrom.userDetection = [];
    this.dateShowFrom.userHealth = [];
    this.healthTotal = 0;
    this.type = "day";
  }

  dataHandleInit() {
    let type = this.type;
    let storeId = this.curStoreId;
    getUserBusinessCount({ storeId: storeId, type: type }) .then((res) => {
      this.totalMoney = formatMoney((res.data.totalMoney ?? 0) / 100);
      this.totalCash = formatMoney((res.data.totalCash ?? 0) / 100);
      this.totalCard = formatMoney((res.data.totalCard ?? 0) / 100);
      this.course = formatMoney((res.data.course ?? 0) / 100);
      this.goodsActualIncome = formatMoney((res.data.goodsActualIncome ?? 0) / 100);
      this.serviceActualIncome = formatMoney((res.data.serviceActualIncome ?? 0) / 100);
    })
    detectionDeviceDeadline({ storeId: storeId, type: type })
      .then((res) => {
        this.dateShowFrom.detectionDevice = res.data || [];
      })
      .catch((err) => {});
    healthDeviceDeadline({ storeId: storeId, type: type })
      .then((res) => {
        this.dateShowFrom.healthDevice = res.data || [];
      })
      .catch((err) => {});
    userDataCount({ storeId: storeId, type: type })
      .then((res) => {
        this.dateShowFrom.userData = res.data || [];
      })
      .catch((err) => {});
    userDetectionCount({ storeId: storeId, type: type })
      .then((res) => {
        this.dateShowFrom.userDetection = res.data || [];
      })
      .catch((err) => {});
    userHealthCount({ storeId: storeId, type: type })
      .then((res) => {
        this.dateShowFrom.userHealth = res.data || [];
      })
      .catch((err) => {});
      healthTotalCount({ storeId: storeId, type: type })
      .then((res) => {
        this.healthTotal = res.data || 0;
      })
      .catch((err) => {});
  }

  changeDate() {
    this.dataHandleInit();
  }

  dialogClose() {
    this.dialogVisible = false;
    this.formData = deepCopy(initFormData);
    this.$refs["validateForm"].resetFields();
    this.storeMemberList = []
  }
  dialogConfirm() {
    let parms = {};
    this.$refs["validateForm"].validate((valid) => {
      if (valid) {
        parms = deepCopy(this.formData);
        parms.userInfo = [];
        const { saaSStoreName = "", saaSStoreId = 0 } = parms.saasStore;
        parms.saasStoreName = saaSStoreName;
        parms.saasStoreId = saaSStoreId; // 清空的的时候传0
        if (parms.address && parms.address.length > 1) {
          parms.address = parms.address.join(',');
        } else {
          parms.address = null; // 如果地址数组数据不全则设置为空
        }
        // 解析经纬度设置
        let lngAndLat = parms.longAndLat ? parms.longAndLat.split(',') : [];
        if (lngAndLat.length == 2) {
          let lat = lngAndLat[0];
          let lng = lngAndLat[1];
          // 数据精度控制在十位以内
          lat = lat.length > 8 ? lat.substring(0, 8) : lat;
          lng = lng.length > 8 ? lng.substring(0, 8) : lng;
          // 转换成数字类型存到数据库
          parms.storeLat = parseFloat(lat);
          parms.storeLong = parseFloat(lng);
          // 如果数字转换失败则置为空
          parms.storeLat = parms.storeLat ? parms.storeLat : null;
          parms.storeLong = parms.storeLong ? parms.storeLong : null;
        }

        parms.storeMentor = this.mentorTag ? this.mentorTag.id : 0;
        parms.storeSupport = this.supportTag ? this.supportTag.id : 0;

        this.dynamicTags.forEach((user) => {
          parms.userInfo.push({
            phone: user.phone,
            userId: user.id,
          });
        });
        parms.storeMemberList = this.storeMemberList;
        parms.restDateList = parms.restDateList?.map(el => (dayjs(el).format("YYYY-MM-DD 00:00:00"))) || []
        if (this.formData.id) {
          editStore(parms)
            .then((res) => {
              this.dialogClose();
              this.getData();
            })
            .catch((err) => {});
        } else {
          createStore(parms)
            .then((res) => {
              this.dialogClose();
              this.resetPages();
              this.getData();
            })
            .catch((err) => {});
        }
      } else {
        return false;
      }
    });
  }

  // 下拉查询SaaS系统绑定列表
  getSaasRemote = debounce(function (str) {
    this.saasStoreLoading = true;
    getSaasStoreList({saasStoreName: str, source: this.formData.saasStoreType, pageNum: 1, pageSize: 10})
      .then((res) => {
        this.saasStoreLoading = false;
        this.allSaasStoreList = res.data;
      })
      .catch((err) => {});
  })

  // TODO: check 下拉框选择之后回显不出来，强制刷新就出来了
  saasChange($event) {
    this.$forceUpdate();
  }

  getSaasList(str) {
    if (typeof str == "object") {
      str = "";
    }
    this.getSaasRemote(str);
  }

  getUserList(str) {
    if (typeof str == "object") {
      str = "";
    }
    this.userLoading = true;
    getUserList({
      phone: str
    })
      .then((res) => {
        this.userLoading = false;
        this.allUserList = res.data || [];
      })
      .catch((err) => {
        this.userLoading = false;
      });
  }
  setCurUser(cur) {
    // 手机号为空或者重复添加的数据将自动跳过
    if (!cur.phone || cur.phone.length == 0 || !cur.id) {
      return;
    } else {
      for (let user of this.dynamicTags) {
        if (user.id == cur.id) {
          return;
        }
      }
    }
    this.dynamicTags.push(cur);
  }
  setCurClerk(cur) {
    // 手机号为空或者重复添加的数据将自动跳过
    if (!cur.phone || cur.phone.length == 0 || !cur.id) {
      return;
    } else {
      for (let user of this.storeMemberList) {
        if (user.id == cur.id) {
          return;
        }
      }
    }
    this.storeMemberList.push(cur);
  }
  handleClose(cur) {
    let temp = this.dynamicTags;
    this.dynamicTags = temp.filter((item) => {
      return item.id != cur.id;
    });
  }
  handleClerkClose(cur) {
    let temp = this.storeMemberList;
    console.log(cur)
    this.storeMemberList = temp.filter((item) => {
      return item.id != cur.id;
    });
  }
  handlerestDateListClose(cur,index) {
    this.formData.restDateList?.splice(index, 1)
  }
  getTongueTimes(times) {
    if (times < 0) return "无限"
    return times;
  }
  getTimeString(time) {
    return dayjs(time)?.format("MM月DD日")
  }

  restDateListListChange($event) {
    const _list = this.formData.restDateList?.sort((el1, el2) => {
      return el1?.getTime() - el2?.getTime()
    })
    this.formData.restDateList = cloneDeep(_list)
  }
}
</script>

<style lang="scss" scoped src="./style.scss"></style>
<style lang="scss">
.fade-enter-active {
  transition: opacity 0.3s;
}
.fade-enter {
  opacity: 0;
}
.store-owner-popup{
  .el-popover__title {
    text-align: center;
  }
}

</style>
